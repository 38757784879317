import { Editor } from '@tinymce/tinymce-react';

const RichTextArea = ({ text, iso, block, detail, height, onChange }) => {

    return (
        <>
            <Editor
                apiKey='kdb3r1voef81zp9ax22krz52kh6v3ou03awk55vy8twfd1oo'
                onEditorChange = {(evt, editor) => {detail && onChange(block, editor.getContent(), iso)}}
                init={{
                    menubar:false,
                    contextmenu: 'cut copy paste pastetext link',
                    font_family_formats: 'Great Vibes=GreatVibes; Montserrat=Montserrat; Josefine Sans=JosefineSans; Lora=Lora; ' +
                        'Open Sans=OpenSans; Roboto=Roboto; Bitter=Bitter; Arial=Arial; Consolas=Consolas; Calibri=Calibri' +
                        'Cambria=Cambria; Centaur=Centaur; Sans Serif=SansSerif; Arial=Arial',
                    content_style: `
                    @font-face {font-family: "GreatVibes";src: url("/fonts/greatvibes-regular-webfont.woff") format("woff");}
                    @font-face {font-family: "JosefineSans";src: url("/fonts/josefinsans-variablefont_wght-webfont.woff") format("woff");}
                    @font-face {font-family: "Lora";src: url("/fonts/lora-variablefont_wght-webfont.woff") format("woff");}
                    @font-face {font-family: "Montserrat";src: url("/fonts/montserrat-variablefont_wght-webfont.woff") format("woff");}
                    @font-face {font-family: "OpenSans";src: url("/fonts/opensans-variablefont_wdthwght-webfont.woff") format("woff");}
                    @font-face {font-family: "Roboto";src: url("/public/fonts/Roboto-Regular-webfont.woff") format("woff");}
                    @font-face {font-family: "Bitter";src: url("/public/fonts/Bitter-Regular.woff") format("woff");}
                    @font-face {font-family: "Arial";src: url("/public/fonts/ArialMT.woff") format("woff");}
                    @font-face {font-family: "Consolas";src: url("/public/fonts/Consolas.woff") format("woff");}
                    @font-face {font-family: "Calibri";src: url("/public/fonts/Calibri.woff") format("woff");}
                    @font-face {font-family: "Cambria";src: url("/public/fonts/Cambria.woff") format("woff");}
                    @font-face {font-family: "Centaur";src: url("/public/fonts/CentaurMTStd.woff") format("woff");}
                    @font-face {font-family: "SansSerif";src: url("/public/fonts/opensans-variablefont_wdthwght-webfont.woff") format("woff");}
}
                    body { font-family: "Roboto" }
                    `,
                    language: "es",
                    language_url: "/tinymce/es.js",
                    branding: false,
                    statusbar: false,
                    height: height
                }}
                plugins="lists autolink link"
                toolbar="undo redo | fontfamily fontsize blockformats | alignleft aligncenter alignright alignjustify | bold italic underline removeformat | bullist numlist | outdent indent | link"
                value={detail && text}
            />
        </>
    );
}

export default RichTextArea